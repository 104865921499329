import React from 'react';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import NotInterestedOutlinedIcon from '@mui/icons-material/NotInterestedOutlined';
import ColorPalette from '../../../../../colorpalette/ColorPalette';

const ListItem = ({ id, date, name, cpf, amountCharged,  invoiceData, issueDocument }) => {

    const time = new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const formattedDate = new Date(date).toLocaleDateString('pt-BR');

    const getCheckedItem = () => {
        return <CheckOutlinedIcon htmlColor={ColorPalette.green} fontSize='verysmall'/>;
    }

    const getUncheckedItem = () => {
        return <NotInterestedOutlinedIcon htmlColor={ColorPalette.red} fontSize='verysmall'/>;
    }

    return (
        <tr key={id} >
            <td>{formattedDate}</td>
            <td>{name}</td>
            <td>{cpf}</td>
            {invoiceData ?  <td>{invoiceData.price}</td>:<td></td>}
            <td>{invoiceData != null? getCheckedItem() : getUncheckedItem() }</td>
            {invoiceData ?  <td>{invoiceData.issueDocument == "1" ? getCheckedItem() : getUncheckedItem()}</td>:<td></td>}
        </tr>
    );
};

export default ListItem;