import React from 'react';
import './PatientItem.css';
import PatientStatus from "./status/PatientStatus";
import { NavLink } from 'react-router-dom';
import CustomButtom from '../../../components/buttons/CustomButton';
import IconPatientData from '@mui/icons-material/ContactPageOutlined';
import { GetFriendlyCPF } from '../../../utils/Utils';
import { SendWhatsAppMessage } from "../../../api/WhatsappAPI";
import IconWhatsapp from '@mui/icons-material/WhatsApp';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import MessagesTemplate from '../../../consts/MessagesTemplate';

function PatientItem(props) {
    const { Id, Status, Name, CPF, Phone, OnSelect } = props;

    function OnSelectHandler() {
        OnSelect(Id);
    }

    function onWhatsappClickHandler() {
        const message = "Olá!";
        SendWhatsAppMessage(Phone, MessagesTemplate.ConvertToWhatsAppText(message));
    }

    return (
        <tr key={Id} >
            <td> <PatientStatus Value={Status} /></td>
            <td>{Name}</td>
            <td>{GetFriendlyCPF(CPF)}</td>
            <td>
                <div style={{display:"flex", gap:"10px", alignItems:"center"}}>
                {Phone ? (
                    <>
                        {Phone} 
                        <CustomButtom 
                            style={"secondary"} 
                            variant="outlined" 
                            icon={<IconWhatsapp color='primary' />} 
                            onClick={onWhatsappClickHandler} 
                        />
                    </>
                ) : null}
                </div>
            </td>
            <td>
                <NavLink to={Id} style={{ textDecoration: 'none' }}>
                    <CustomButtom 
                        style={"secondary"} 
                        variant="outlined" 
                        icon={<IconPatientData />} 
                        onClick={OnSelectHandler} 
                    />
                </NavLink>
            </td>
        </tr>
    );
}

export default PatientItem;
