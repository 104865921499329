import React, { useRef, useEffect } from 'react';
import AreaTitle from '../../../../components/areaTitle/AreaTitle';
import DateRangeHeader from '../../../../components/dateRangeHeader/DateRangeHeader';
import { DataProviderInstance } from '../../../../api/DataProvider';
import ListItem from './list/ListItem';
import styles from './Financial.module.css';
import FilterList from '../../filters/FilterList';
import Loading from '../../../../components/loading/Loading';

const Financial = () => {

    const { GetReportsFinancial, GetPatientById } = DataProviderInstance();

    const HAS_INVOICE = "Faturadas"
    const NEEDS_DOCUMENT_INVOICE = "Faturadas e pediram nota fiscal"
    const filters = [HAS_INVOICE, NEEDS_DOCUMENT_INVOICE];

    const [list, setList] = React.useState([]);
    const [filtered, setFiltered] = React.useState([]);
    const [currentFilters, setCurrentFilters] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);	


    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);

    const [total, setTotal] = React.useState(0);
    const [totalIssued, setTotalIssued] = React.useState(0);

    const filterRef = useRef(null);

    useEffect(() => {
        
        const today = new Date();
        const initialDate = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split('T')[0];
        const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString().split('T')[0];

        handleDateChange(initialDate, endDate);
    }, []);

    
    useEffect(() => {
        GetFilteredList();
    }, [currentFilters]);

    useEffect(() => {
       // resetFilters();
       calculateTotal();
        GetFilteredList();
    }, [list]);

  
    const calculateTotal = () => {
        let total = 0;
        let totalIssued = 0;
        list.forEach(item => {
            total += Number(item.invoice?.price) || 0;
            if (item.invoice?.issueDocument == "1") {
                totalIssued += Number(item.invoice.price);
            }
        });

        setTotal(total);
        setTotalIssued(totalIssued);
    }
    const resetFilters = () => {
        setCurrentFilters([]);
    }

    const handleDateChange = (initialDate, endDate) => {
        setStartDate(initialDate);
        setEndDate(endDate);

        loadData(initialDate, endDate);
    }

    const loadData = (initialDate, endDate) => {
        setIsLoading(true);
        GetReportsFinancial(initialDate, endDate, (response) => {
            setList(response.data);
            setIsLoading(false);
        } );
    }

    function renderItem(item, index) {
        const patientData = GetPatientById(item.patient);

        return (
            <ListItem key={index} id={item.id} date={item.date} name={patientData.name} cpf={patientData.cpf} invoiceData={item.invoice}/>
        );
    }

    const filterChangeHandler = (filters) => {
        setCurrentFilters(filters);
    }

    const GetFilteredList = () => {
        let filteredList = [...list];

        if (currentFilters.includes(1)) { //NEEDS_DOCUMENT_INVOICE
            filteredList = filteredList.filter(item => item.invoice && item.invoice.issueDocument == "1");
        }

        if (currentFilters.includes(0)) { // HAS_INVOICE
            filteredList = filteredList.filter(item => item.invoice);
        }

        setFiltered(filteredList);
    }

    const totalStr = total.toFixed(2).replace('.', ',');
    const totalIssuedStr = totalIssued.toFixed(2).replace('.', ',');

    return (
        <div className={styles.mainContainer}>
            <AreaTitle Title="Financeiro" />
            <DateRangeHeader onChange={handleDateChange} />
            <FilterList ref={filterRef} id="reportsFinancial" label="Filtros" filters={filters} onChange={filterChangeHandler} initOpened={true}/>
            {isLoading && <Loading />}
            <div className={styles.containerResume}>
            <AreaTitle Title="Resultado" Description={`<b>${list.length} consultas</b> encontradas no período de <b>${formatDate(startDate)} à ${formatDate(endDate)}</b>\nTotal recebido: R$<b>${totalStr}</b>, com nota fiscal R$<b>${totalIssuedStr}</b>.`}/>
            </div>
            <div className={styles.containerTable}>
             <table className={styles.table}>
                <thead>
                    <tr className={styles.tr}>
                        <th>Data</th>
                        <th>Paciente</th>
                        <th>CPF</th>
                        <th>Valor</th>
                        <th>Faturado</th>
                        <th>Emitir nota fiscal?</th>
                    </tr>
                </thead>
                <tbody>
                    {filtered && filtered.map((item, index) => (
                       renderItem(item, index)
                    ))}
                </tbody>
            </table>
            </div>
        </div>
    );

    function formatDate(date) {
        if (!date) return '';
        const [year, month, day] = date.split('-');
        return `${day}/${month}/${year}`;
    }
};

export default Financial;