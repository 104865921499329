import React, { useEffect } from 'react';
import { useState } from "react";
import PopupBase from "../base/PopupBase";
import CustomButton from "../../components/buttons/CustomButton";
import { TextField, Select, MenuItem, FormControl, InputLabel,FormControlLabel, Switch } from "@mui/material";
import IconMoney from '@mui/icons-material/MonetizationOnOutlined';
import { DataProviderInstance } from "../../api/DataProvider";
import { GetFriendlyCPF } from '../../utils/Utils';

function CreateInvoicePopup(props) {

    const getModifier = (id) => {
        return CompanyConfig.PaymentOptions.find(item => item.Id == id).PriceModifier;
    }

    const getPrice = () => {
        let modifier = getModifier(paymentMethod);
        return Number(Data.price) + Number(modifier);
    }

    const { Data, OnHide, OnCreated } = props;

    const { CompanyConfig, SetAppointmentInvoice, UpdateInvoice, GetPatientById, GetProcedureById, SetPatientDocument, GetInvoice } = DataProviderInstance();

    const [paymentMethod, setPaymentMethod] = useState(CompanyConfig.PaymentOptions[0]?.Id);
    const [currentPrice, setPrice] = useState(getPrice());
    const [issueDocument, setIssueDocument] = useState(0);
    const [editing, setEditing] = useState(Data.invoiceId != "0");

    const [invoiceData, setInvoiceData] = useState(null);

    useEffect(() => {

        setPrice(getPrice());
    }, [paymentMethod]);

    useEffect(() => {
        if(editing)
        {
            GetInvoice(Data.invoiceId, (data) => {
                console.log("invoice" , data.invoice);
                setInvoiceData(data); 
            });
        }

    }, [editing]);

    useEffect(() => {
        if(invoiceData != null)
        {
            setPrice(invoiceData.invoice.price);
            setPaymentMethod(invoiceData.invoice.paymentMethod);
            setIssueDocument(invoiceData.invoice.issueDocument == "1" ? 1 : 0);
        }
    }, [invoiceData]);

    function OnCloseClickHandler() {
        Hide(false);
    }

    function Hide(needsPageReload) {
        OnHide(needsPageReload);
    }

    function createInvoiceHandler() {

        const appointmentId = Data.id;
        const patient = Data.patient;
        const price = currentPrice;
        const items = Data.procedure;
        const pay = paymentMethod;
        const issue = issueDocument;

        SetAppointmentInvoice(appointmentId, patient, price, pay, items, issue, (response) => {

            createInvoiceDocument((response) => {
                Hide(true);
            });

        });
    }
    
    function updateInvoiceHandler() {

        const invoiceId = invoiceData.invoice.id;

        const price = currentPrice;
        const pay = paymentMethod;
        const issue = issueDocument;

        console.log("updateInvoiceHandler", invoiceId, price, pay, issue);

        UpdateInvoice(invoiceId, price, pay, issue, (response) => {
            Hide(true);
        });
    }


    function createInvoiceDocument(callback) {
        const appointmentData = Data;
        const patientData = GetPatientById(appointmentData.patient);
        const procedureData = GetProcedureById(appointmentData.procedure);
        const appointmentDate = new Date(appointmentData.date);

        const documentTemplate = CompanyConfig.DocumentTemplates.find(item => item.Tag == "Recibo");

        const currentTitle = documentTemplate.Title;

        const friendyDate = appointmentDate.toLocaleDateString('pt-BR', { year: 'numeric', month: 'long', day: 'numeric' });

        const cpf = GetFriendlyCPF(patientData.cpf);
        const price = currentPrice;

        const paymentMethodLabel = CompanyConfig.PaymentOptions.find(item => item.Id == paymentMethod).Label;

        const currentText = documentTemplate.Text
            .replace("{patient-name}", "" + patientData.name + "")
            .replace("{patient-cpf}", "" + cpf + "")
            .replace("{appointment-date}", "" + friendyDate + "")
            .replace("{appointment-price}", "" + price + " (" + paymentMethodLabel + ")");

        const formattedAppointmentDate = appointmentDate.toLocaleDateString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' }).split('/').reverse().join('-');

        SetPatientDocument(appointmentData.patient, formattedAppointmentDate, appointmentData.id, currentTitle, currentText, (response) => {
            callback(response);
        });
    }

    function handlePaymentMethodChange(event) {
        setPaymentMethod(event.target.value);
    }

    function GetPositiveButton() {
        if(editing)
        {
            return <CustomButton variant="contained" style="primary" label={"Salvar"} onClick={updateInvoiceHandler} />
        }

        return <CustomButton variant="contained" style="primary" label={"Criar fatura"} icon={<IconMoney />} onClick={createInvoiceHandler} />
    }

    function getModifierLabel(id) {
        const modifier = CompanyConfig.PaymentOptions.find(item => item.Id == id).PriceModifier;

        if(modifier == 0)
        {
            return "";
        }
        else if(modifier > 0)
        {
            return "(+ R$" + modifier + ")";
        }
        else
        {
            return "(- R$" + (modifier*-1) + ")";
        }
    }

    function GetContent() {

        let friendlyDate = new Date().toLocaleDateString("pt-PT", { year: 'numeric', month: 'numeric', day: 'numeric' });
  
        return (
            <div style={{ marginTop: "10px" }}>
                <div className="form">
                    <div className="form-group">
                        <div className="form-line">
                            <TextField label="Procedimento" value={GetProcedureById(Data.procedure).Label} disabled />
                            <TextField label="Data" value={friendlyDate} disabled />
                        </div>
                    </div>
                    <TextField label="Paciente" value={GetPatientById(Data.patient).name} disabled />
                    <div className="form-group">
                        <div className="form-line">
                            <TextField label="Valor" type="number"
                                value={currentPrice} onChange={(e) => { setPrice(e.target.value) }} />
                            <FormControl style={{ flex: "1" }}>
                                <InputLabel>Metodo de pagamento</InputLabel>
                                <Select
                                    defaultValue={paymentMethod}
                                    label="Metodo de pagamento"                                   
                                    onChange={handlePaymentMethodChange}>
                                    {CompanyConfig.PaymentOptions.map((option) => (
                                        <MenuItem value={option.Id}>{option.Label} {getModifierLabel(option.Id)}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>                           
                        </div>
                        <div className="form-line">
                            <FormControlLabel control={<Switch checked={issueDocument} onChange={(e) => { setIssueDocument(e.target.checked ? 1 : 0) }} />} label="Emitir nota fiscal ?" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const GetTitle = () => {
        if(editing)
        {
            return "Editar faturamento" ;
        }
        
        return "Faturar consulta";
    }

    return <PopupBase Title={GetTitle()} Content={GetContent()} Footer={GetPositiveButton()} OnClose={OnCloseClickHandler} />
}

export default CreateInvoicePopup;