import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, TextField } from "@mui/material";
import CustomButton from '../buttons/CustomButton';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import styles from './DateRangeHeader.module.css';

const DateRangeHeader = ({  onChange }) => {

    const [currentStartDate, setCurrentStartDate] = useState(dayjs(new Date(), 'YYYY-MM-DD'));
    const [currentEndDate, setCurrentEndDate] = useState(dayjs(new Date(), 'YYYY-MM-DD'));

    useEffect(() => {
        handleThisMonth();
        handleShow();
    }
     , []);

     useEffect(() => {
        handleShow();
    }
        , [currentStartDate, currentEndDate]);


    const handleShow = () => {
        onChange(currentStartDate.format('YYYY-MM-DD'), currentEndDate.format('YYYY-MM-DD'));
    }

    const handleToday = () => {
        const today = new Date();
        setCurrentStartDate(dayjs(today));
        setCurrentEndDate(dayjs(today));
    }
    const handleThisWeek = () => {
        const today = new Date();
        const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
        const endOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 6));
        setCurrentStartDate(dayjs(startOfWeek));
        setCurrentEndDate(dayjs(endOfWeek));
    }

    const handleThisMonth = () => {
        const today = new Date();
        const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        setCurrentStartDate(dayjs(startOfMonth));
        setCurrentEndDate(dayjs(endOfMonth));
    }

    const handleLast6Months = () => {
        const today = new Date();
        const startOfMonth = new Date(today.getFullYear(), today.getMonth() - 6, 1);
        const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        setCurrentStartDate(dayjs(startOfMonth));
        setCurrentEndDate(dayjs(endOfMonth));
    }

    const handleLastYear = () => {
        const today = new Date();
        const startOfMonth = new Date(today.getFullYear() - 1, 0, 1);
        const endOfMonth = new Date(today.getFullYear() - 1, 11, 31);
        setCurrentStartDate(dayjs(startOfMonth));
        setCurrentEndDate(dayjs(endOfMonth));
    }

    const handleFromTheBeginning = () => {
        const today = new Date();
        const startOfMonth = new Date(1500, 0, 1);
        const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        setCurrentStartDate(dayjs(startOfMonth));
        setCurrentEndDate(dayjs(endOfMonth));
    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.containerDatePicker}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Data de Início"
                        defaultValue={dayjs(new Date(), 'YYYY-MM-DD')}
                        value={currentStartDate}
                        format='DD/MM/YYYY'
                        onChange={(date) => setCurrentStartDate(date)}
                        renderInput={(params) => <TextField {...params} />}
                    />

                    <DatePicker
                        label="Data de Fim"
                        defaultValue={dayjs(new Date(), 'YYYY-MM-DD')}
                        format='DD/MM/YYYY'
                        value={currentEndDate}
                        onChange={(date) => setCurrentEndDate(date)}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </div>
            <div className={styles.containerShortcuts}>
                <CustomButton variant="outlined" style={"primary"} label={"Hoje"} onClick={handleToday} />
                <CustomButton variant="outlined" style={"primary"} label={"Essa semana"} onClick={handleThisWeek} />
                <CustomButton variant="outlined" style={"primary"} label={"Esse mês"} onClick={handleThisMonth} />
                <CustomButton variant="outlined" style={"primary"} label={"Últimos 6 meses"} onClick={handleLast6Months} />
                <CustomButton variant="outlined" style={"primary"} label={"Último ano"} onClick={handleLastYear} />
                <CustomButton variant="outlined" style={"primary"} label={"Desde o início"} onClick={handleFromTheBeginning} />
            </div>
            {/*<CustomButton variant="contained" style={"primary"} label={"Visualizar"} onClick={handleShow} />*/}
        </div>
    );
};

export default DateRangeHeader;