import React, { useState, useRef, useEffect } from "react";
import ColorPallete from '../../colorpalette/ColorPalette';
import './CreatePatientPopup.css';
import PopupBase from "../base/PopupBase";
import InputField from "../../components/inputfield/InputField";
import CustomButtom from "../../components/buttons/CustomButton";
import RadioSelector from '../../components/radio-selector/RadioSelector';
import PatientConfig from "../../consts/PatientConfig";
import { DataProviderInstance } from '../../api/DataProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import CustomButton from "../../components/buttons/CustomButton";
import { FormControl, InputLabel, Select, MenuItem, FormControlLabel, FormGroup, Switch } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function CreatePatientPopup(props) {

    const
        {
            CompanyConfig,
            GetCEPInfo,
            SetPatient, UpdatePatient, patients
        } = DataProviderInstance();

    const navigate = useNavigate();

    const { Data, Visibility, OnChange, OnHide } = props;

    const inputFieldCPFRef = useRef(null);
    const inputFieldNameRef = useRef(null);
    const inputFieldBirthdayRef = useRef(null);
    const genderRef = useRef(null);
    const inputFieldPhoneRef = useRef(null);
    const inputFieldEmailRef = useRef(null);
    const inputFieldCEPRef = useRef(null);
    const inputFieldAddressRef = useRef(null);
    const inputFieldNumberRef = useRef(null);
    const inputFieldCompRef = useRef(null);
    const inputFieldBairroRef = useRef(null);
    const inputFieldCidadeRef = useRef(null);
    const inputFieldEstadoRef = useRef(null);

    const [currentData, setCurrendData] = useState(Data);
    const [currentStatus, setCurrentStatus] = useState(Data != null ? Data.status : 1);
    const [currentOriginIndex, setCurrentOriginIndex] = useState(0);
    const [patientFound, setPatientFound] = useState(null);

    useEffect(() => {

        setCurrentOriginIndex(Data != null ? GetPatientOriginIndexById(Data.origin) : 0);

        return () => {
            Dispose();
        };
    }, []);

    function Dispose() {
        //setCurrentStatus(1);
        // setCurrentOriginIndex(0);
    }

    function ValidateForm() {
        //inputFieldCPFRef.current.Validate() && 
        return inputFieldNameRef.current.Validate() &&
            inputFieldPhoneRef.current.Validate()
    }

    function OnConfirmClickHandler() {
        if (ValidateForm()) {
            let id = (Data != null) ? Data.id : null;
            let patientOrigins = CompanyConfig.PatientOrigins[currentOriginIndex] ? CompanyConfig.PatientOrigins[currentOriginIndex].Id : null;

            let birthday = inputFieldBirthdayRef.current.GetValue();
            
            if (birthday) {
                const [day, month, year] = birthday.split('/');
                birthday = `${year}-${month}-${day}`;
            }

            let data = CreatePatienteData(
                id,
                currentStatus,
                inputFieldCPFRef.current.GetValue(),
                inputFieldNameRef.current.GetValue(),
                birthday,
                genderRef.current.GetValue(),
                inputFieldPhoneRef.current.GetValue(),
                inputFieldEmailRef.current.GetValue(),
                inputFieldCEPRef.current.GetValue(),
                inputFieldAddressRef.current.GetValue(),
                inputFieldNumberRef.current.GetValue(),
                inputFieldCompRef.current.GetValue(),
                inputFieldBairroRef.current.GetValue(),
                inputFieldCidadeRef.current.GetValue(),
                inputFieldEstadoRef.current.GetValue(),
                patientOrigins
            );

            Hide(data);
        }
    }

    function GetFullAdressByCEP(value) {
        setLoadingAddress(true);

        GetCEPInfo(value, (result) => {
            inputFieldAddressRef.current.SetValue(result.logradouro);
            inputFieldBairroRef.current.SetValue(result.bairro);
            inputFieldCidadeRef.current.SetValue(result.localidade);
            inputFieldEstadoRef.current.SetValue(result.uf);

            setLoadingAddress(false);

        });
    }

    function CreatePatienteData(id, status, cpf, name, birthday, gender, phone, email, cep, address, number, complement, district, city, state, origin) {

        let patientData = {
            id: id,
            status: status,
            cpf: cpf,
            name: name,
            birthday: birthday,
            gender: gender,
            phone: phone,
            email: email,
            cep: cep,
            address: address,
            number: number,
            complement: complement,
            district: district,
            city: city,
            state: state,
            origin: origin
        }

        if (id != null) {
            UpdatePatient(patientData, () => {

                if (OnChange != null) {
                    OnChange();
                }
            })
        }
        else {
            SetPatient(patientData);
        }

        return patientData;
    }

    function OnCloseClickHandler() {
        Hide();
    }

    function Hide() {
        Dispose();
        OnHide();
    }

    function GetTitle() {
        if (Data && Data.id) {
            return "Editar paciente";
        }
        else {
            return "Novo paciente";
        }
    }

    function OnStateChangeHandler(value) {
        setCurrentStatus((value ? 1 : 0));
    }

    function OnGenderSelectHandler(value) {

    }

    function GetPatientOriginIndexById(id) {

        let index = CompanyConfig.PatientOrigins.findIndex(x => x.Id == id);
        return index;
    }

    const [loadingAddress, setLoadingAddress] = useState(false);

    function OnSearchCEPClickHandler() {
        let cep = inputFieldCEPRef.current.GetValue();

        if (cep.trim() != "") {
            GetFullAdressByCEP(cep);
        }
    }

    function GetSearchIcon() {
        return <FontAwesomeIcon icon={faMagnifyingGlass} color={ColorPallete.secondary} style={{ fontSize: '16px' }} />
    }

    function OnCPFChangeHandler(value) {

        const rawValue = value.replace(/[^0-9]/g, '');

        if (rawValue.length > 0) {
            const existingPatient = patients.find(patient => patient.cpf === rawValue);

            if (existingPatient) {
                setPatientFound(existingPatient);
                inputFieldCPFRef.current.SetError("CPF já cadastrado");
                // CPF already exists, handle accordingly
            } else {
                // CPF is unique, continue with the logic
                setPatientFound(null);
            }
        }
        else {
            setPatientFound(null);
        }
    }

    function onFoundPatientClickHandler() {
        navigate(`/patients/${patientFound.id}`);

    }

    function GetContent() {
        let currentName = "";
        let currentGender = "";
        let currentPhone = "";
        let currentCPF = "";
        let currentEmail = "";
        let currentBirthday = "";



        let currentCEP = "";
        let currentAddress = "";
        let currentNumber = "";
        let currentComp = "";
        let currentBairro = "";
        let currentCidade = "";
        let currentEstado = "";


        if (Data) {
            currentName = Data.name;
        }

        if (Data && Data.id) {
            currentCPF = Data.cpf;

            if (Data.birthday) {
                const [year, month, day] = Data.birthday.split('-');
                currentBirthday = `${day}/${month}/${year}`;
            }

            currentGender = Data.gender;
            currentPhone = Data.phone;
            currentEmail = Data.email;

            currentCEP = Data.cep;
            currentAddress = Data.address;
            currentNumber = Data.number;
            currentComp = Data.complement;
            currentBairro = Data.district;
            currentCidade = Data.city;
            currentEstado = Data.state;
        }

        return (
            <div>
                <div className="form" >
                    {Data != null &&
                        <div className="form-group">
                            <span className="form-group-title">Situação do cadastro</span>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch checked={currentStatus == 1} onChange={(event) => OnStateChangeHandler(event.target.checked)} />}
                                    label="Ativo"
                                />
                            </FormGroup>
                        </div>}
                    <span className="form-group-title">Dados pessoais:</span>
                    <div className="form-group">
                        <div className="form-line">
                            <InputField Mandatory={false} Value={currentCPF} Label={"CPF"} ref={inputFieldCPFRef} Mask={"999.999.999-99"} OnUpdateValue={OnCPFChangeHandler} />
                            {patientFound && <div>
                                <div className="form-line">
                                    <div>Paciente já cadastrado</div>
                                    <CustomButtom label={patientFound.name} style={"secondary"} variant="outlined" icon={GetSearchIcon()} onClick={onFoundPatientClickHandler} />
                                </div>
                            </div>
                            }
                        </div>



                        <div className="form-line">
                            <InputField Mandatory={true} Value={currentName} Label={"Nome"} ref={inputFieldNameRef} Disabled={patientFound != null} />
                        </div>
                        <div className="form-line">
                            <InputField Mandatory={false} Value={currentBirthday} Label={"Data nascimento"} ref={inputFieldBirthdayRef} Mask={"99/99/9999"} Disabled={patientFound != null} />
                            <RadioSelector Label={"Gênero"} Mandatory={false} Options={PatientConfig.Genders} InitialSelection={currentGender} OnSelect={OnGenderSelectHandler} ref={genderRef} Disabled={patientFound != null} />
                        </div>
                    </div>
                    <span className="form-group-title">Contatos:</span>
                    <div className="form-group">
                        <div className="form-line">
                            <InputField Mandatory={true} Value={currentPhone} Label={"Telefone"} ref={inputFieldPhoneRef} Mask={"(99) 99999-9999"} Disabled={patientFound != null} />
                            <InputField Mandatory={false} Value={currentEmail} Label={"E-mail"} ref={inputFieldEmailRef} Disabled={patientFound != null} />
                        </div>
                    </div>
                    <span className="form-group-title">Endereço:</span>
                    <div className="form-group">
                        <div className="form-line">
                            <div style={{ display: "flex", gap: "10px" }}>
                                <InputField Mandatory={false} Value={currentCEP} Label={"CEP"} ref={inputFieldCEPRef} Mask={"99999-999"} Disabled={patientFound != null} />
                                <CustomButtom style={"secondary"} variant="outlined" icon={GetSearchIcon()} onClick={OnSearchCEPClickHandler} enabled={patientFound == null} />
                            </div>
                            <InputField Mandatory={false} Value={currentAddress} Label={"Endereço"} ref={inputFieldAddressRef} Disabled={loadingAddress || patientFound != null} />
                        </div>
                        <div className="form-line">
                            <InputField Mandatory={false} Value={currentNumber} Label={"Número"} ref={inputFieldNumberRef} Disabled={loadingAddress || patientFound != null} />
                            <InputField Mandatory={false} Value={currentComp} Label={"Complemento"} ref={inputFieldCompRef} Disabled={loadingAddress || patientFound != null} />
                        </div>
                        <div className="form-line">
                            <InputField Mandatory={false} Value={currentBairro} Label={"Bairro"} ref={inputFieldBairroRef} Disabled={loadingAddress || patientFound != null} />
                            <InputField Mandatory={false} Value={currentCidade} Label={"Cidade"} ref={inputFieldCidadeRef} Disabled={loadingAddress || patientFound != null} />
                            <InputField Mandatory={false} Value={currentEstado} Label={"Estado"} ref={inputFieldEstadoRef} Disabled={loadingAddress || patientFound != null} />
                        </div>
                    </div>
                    <span className="form-group-title">Outros:</span>
                    <div className="form-group">
                        <div className="form-line">
                            <FormControl fullWidth disabled={patientFound != null}>
                                <InputLabel>Origem do lead:</InputLabel>
                                <Select
                                    value={currentOriginIndex}
                                    onChange={(event) => {
                                        setCurrentOriginIndex(event.target.value);
                                    }}
                                >
                                    {CompanyConfig.PatientOrigins.map((origin, index) => (
                                        <MenuItem key={index} value={index}>{origin.Label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function GetPositiveButton() {

        let callToAction = "";

        if (Data && Data.id) {
            callToAction = "Salvar";
        }
        else {
            callToAction = "Adicionar";
        }

        return (
            <CustomButton style="primary" variant="contained" label={callToAction} onClick={OnConfirmClickHandler} enabled={!patientFound} />
        )
    }


    function GetNegativeButton() {

        return (
            <CustomButton label={"Cancelar"} onClick={OnCloseClickHandler} />
        )
    }

    function GetFooter() {
        return (
            <div style={{ display: "flex", gap: "10px" }}>
                <CustomButton label={"Cancelar"} onClick={OnCloseClickHandler} />
                {GetPositiveButton()}
            </div>
        )
    }


    function GetRender() {
        if (Visibility)
            return <PopupBase Title={GetTitle()} Content={GetContent()} Footer={GetFooter()} OnClose={OnCloseClickHandler} />
        else
            return null;
    }

    return (GetRender());
}

export default CreatePatientPopup;