import React, { useState, useRef, useEffect } from "react";
import { TextField, Select, MenuItem, FormControl, InputLabel, Autocomplete, createFilterOptions, CircularProgress, ListItemText } from "@mui/material";

import { useNavigate } from 'react-router-dom';
import './AppointmentPopupContent.css';
import PopupBase from "../base/PopupBase";
import ConfirmPopup from "../ConfirmPopup/ConfirmPopup";
import Header from "./header/Header";
import InputField from "../../components/inputfield/InputField";
import AppointmentsState from "../../consts/AppointmentsState";
import CalendarConfig from '../../consts/CalendarConfig';
import AppointmentStatusSelector from "./StatusSelector/AppointmentStatusSelector";
import CreatePatientPopup from '../CreatePatientPopup/CreatePatientPopup';
import { DataProviderInstance } from '../../api/DataProvider';
import MessagesTemplate from "../../consts/MessagesTemplate";
import CustomButton from "../../components/buttons/CustomButton";
import IconService from '@mui/icons-material/LocalHospitalOutlined';
import IconCreatePatient from '@mui/icons-material/PersonAddAlt1Outlined';
import IconMoney from '@mui/icons-material/MonetizationOnOutlined';
import IconReceipt from '@mui/icons-material/ReceiptLongOutlined';
import IconWhatsapp from '@mui/icons-material/WhatsApp';
import IconPatientData from '@mui/icons-material/ContactPageOutlined';
import { Check } from '@mui/icons-material';

import { SendWhatsAppMessage } from "../../api/WhatsappAPI";
import { APPOINTMENT_GET_RECEIPT } from '../../api/API';
import { Switch } from "@mui/material";

function AppointmentPopupContent(props) {

    const
        {
            CompanyConfig,
            patients, GetPatientById, GetPatients,
            SetAppointment, UpdateAppointment, DeleteAppointment,
            GetProcedureById, GetResourceById

        } = DataProviderInstance();

    const { Data, OnCreateInvoice,OnViewInvoice, OnHide } = props;

    const keySendWhatssappReminder = "sendAppointmentCreationWhatssappReminder";

    const [createPatientPopupVisibility, ShowCreatePatientPopup] = useState(false);
    const [deleteAppointmentPopupVisibility, ShowDeleteAppointmentPopup] = useState(false);
    const [sendWhatssappReminder, setSendWhatssappReminder] = useState(localStorage.getItem(keySendWhatssappReminder) === "true");

    const [patientData, setPatientData] = useState(null);
    const [patientsList, setPatientsList] = useState([]);
    const [sortedPatients, setSortedPatients] = useState([]);

    const [resource, setResource] = useState(null);
    const [procedure, setProcedure] = useState(null);

    const statusSelectorRef = useRef(null);
    const inputPriceRef = useRef(null);
    const inputDurationRef = useRef(null);

    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState(false);

    const navigate = useNavigate();

    const filter = createFilterOptions();


    useEffect(() => {
        localStorage.setItem(keySendWhatssappReminder, sendWhatssappReminder);
    }, [sendWhatssappReminder]);

    useEffect(() => {

        ShowDeleteAppointmentPopup(false);
        ShowCreatePatientPopup(false);

        if (Data.id != null) {
            setPatientData(GetPatientById(Data.patient));
            setResource(GetResourceById(Data.resource));
            setProcedure(GetProcedureById(Data.procedure));
        }
        else {
            setResource(CompanyConfig.Resources[0]);
            setProcedure(CompanyConfig.Procedures[0]);
        }

    }, [Data]);

    function OnCreatePatientPopupHideHandler(data) {
        ShowCreatePatientPopup(false);
    }

    function OnOpenCreatePatientPopupHandler(suggestion) {

        ShowCreatePatientPopup(true);
    }

    function ValidateForm() {

        if (patientData == null || patientData.id == null) {
            setError(true);
            return false;
        }
        else {
            setError(false);
        }

        return true;
    }

    function OnCloseClickHandler() {

        if (Data.status == AppointmentsState.Available) {
            Hide();
        }
        else {
            // Auto save infos;
            if (statusSelectorRef.current.GetValue() !== Data.status) {
                ValidateAndSave();
            }
            else {
                Hide();
            }
        }
    }

    function OnDeleteClickHandler() {
        ShowDeleteAppointmentPopup(true);
    }

    function OnDeleteAppointmentConfirmClickHandler() {
        DeleteAppointment(Data.id);
        Hide();
    }

    function OnDeleteAppointmentCancelClickHandler() {
        ShowDeleteAppointmentPopup(false);
    }

    function OnConfirmClickHandler() {
        ValidateAndSave();
    }

    function ValidateAndSave() {
        if (ValidateForm()) {
            SaveData();
            Hide();
        }
    }

    function SaveData() {
        var date = CalendarConfig.ISOToDate(Data.date);
        var parsedDate = CalendarConfig.GetAAAAMMDD(date);
        var time = CalendarConfig.GetTime(Data.date);

        let patientId = patientData.id;

        let newAppointment = Data.id ? false : true;

        let data = null;

        if (newAppointment) {
            data = CreateAppointmentData(
                null,
                AppointmentsState.Scheduled,
                parsedDate,
                time,
                patientId,
                resource,
                procedure
            );

        }
        else {
            data = CreateAppointmentData(
                Data.id,
                statusSelectorRef.current.GetValue(),
                parsedDate,
                time,
                patientId,
                resource,
                procedure
            );
        }

        return data;
    }

    function getAppointmentCreationMessage(patient, appointmentData, previousAppointments) {

        let message = (previousAppointments == "0") ? MessagesTemplate.AppointmentCreatedFullMessage : MessagesTemplate.AppointmentCreatedShortMessage;

        let date = appointmentData.date;
        let yyyymmdd = CalendarConfig.GetAAAAMMDDArray(date);

        let monthDay = yyyymmdd[2];
        let monthName = CalendarConfig.Months[yyyymmdd[1] - 1];

        let dayOfWeek = CalendarConfig.GetDayOfWeek(date); // Assuming CalendarConfig has a method to get the day of the week

        message = message.replaceAll("{name}", patient.name.split(' ')[0]);
        message = message.replaceAll("{dayOfWeek}", dayOfWeek);
        message = message.replaceAll("{day}", monthDay);
        message = message.replaceAll("{month}", monthName);
        message = message.replaceAll("{time}", CalendarConfig.GetTime(date));
        message = message.replaceAll("{price}", appointmentData.price);
        message = message.replaceAll("{when}", dayOfWeek);

        return message;
    }

    function SendAppointmentDataToClient(patient, message) {

        SendWhatsAppMessage(patient.phone, MessagesTemplate.ConvertToWhatsAppText(message));
    }

    function CreateAppointmentData(id, status, date, time, patient, resource, procedure) {
        var data = {
            id: id,
            status: status,
            date: date + " " + time,
            patient: patient,
            resource: (resource) ? resource.Id : null,
            procedure: (procedure) ? procedure.Id : null,
            duration: (procedure) ? procedure.Duration : null,
            price: (procedure) ? procedure.Price : null,
        }

        // Create or update a appointment;
        if (id == null) {
            SetAppointment(data, (response) => {

                const patient = GetPatientById(data.patient);

                const message = getAppointmentCreationMessage(patient, data, response.previousAppointments);

                navigator.clipboard.writeText(MessagesTemplate.ConvertToClipboardText(message));

                if (sendWhatssappReminder) {
                    SendAppointmentDataToClient(patient, message);
                }
            });
        }
        else {
            UpdateAppointment(data);
        }

        return data;
    }

    function Hide() {
        OnHide();
    }

    function createInvoiceHandler() {
        OnCreateInvoice(Data);
    }

    function viewInvoiceHandler() {
        OnViewInvoice(Data);
    }

    function showInvoiceHandler() {
        window.open(APPOINTMENT_GET_RECEIPT + "?id=" + Data.id, "_blank");
    }

    function OnStartServiceHandler() {
        const appointmentId = Data.id;
        navigate(`/appointment/${appointmentId}`);
    }

    function OnPatientInfoClickHandler() {
        navigate(`/patients/${patientData.id}`);
    }

    function OnSendMessageClickHandler() {
        let phone = patientData.phone;
        let patientName = patientData.name.split(' ')[0];
        let message = MessagesTemplate.ConvertToWhatsAppText("Olá " + patientName + ", tudo bem?");

        SendWhatsAppMessage(phone, message);
    }

    function GetEditAppointmentForm() {

        return (
            <div className="form" style={{ marginTop: "10px" }}>   

                <div className="form-group">
                    <div className="form-line">
                        <AppointmentStatusSelector Status={Data.status} ref={statusSelectorRef} />
                       
                        {/*Data.status === AppointmentsState.Done && Data.invoiceId == "0" ?
                            <>
                                <CustomButton variant="outlined" style="primary" label={"Faturar"} icon={<IconMoney />} onClick={createInvoiceHandler} />
                                <CustomButton variant="outlined" style="primary" label={"Ver atendimento"} icon={<IconService />} onClick={OnStartServiceHandler} />
                            </>
                            : Data.status === AppointmentsState.Done && Data.invoiceId !== "0" ?
                                <>
                                    <CustomButton variant="outlined" style="primary" label={"Ver recibo"} icon={<IconReceipt />} onClick={showInvoiceHandler} />
                                    <CustomButton variant="outlined" style="primary" label={"Ver atendimento"} icon={<IconService />} onClick={OnStartServiceHandler} />
                                </>
                                : <></>
                       */ }

                    </div>
                </div>

                
                <div style={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent:"space-between" }}>
                    <CustomButton variant="outlined" style="primary" label={"Ficha do paciente"} icon={<IconPatientData />} onClick={OnPatientInfoClickHandler} />
                    <CustomButton variant="outlined" style="primary" label={"Enviar mensagem"} icon={<IconWhatsapp />} onClick={OnSendMessageClickHandler} />
                     {Data.invoiceId == "0" &&  <CustomButton variant="outlined" style="primary" label={"Faturar consulta"} icon={<IconMoney />} onClick={createInvoiceHandler} />}    
                     { Data.invoiceId != "0" && 
                                <CustomButton variant="outlined" style="primary" label={"Detalhes pagamento"} icon={<IconMoney /> } onClick={viewInvoiceHandler} />
                        }                 
                </div>

                <div className="form-group">
                    <div className="form-line">
                        <InputField Width={"100%"} Label={"Paciente"} Value={GetPatientById(Data.patient).name} Disabled={true} />
                    </div>
                </div>
                <div className="form-group">
                    <div className="form-line">
                        <FormControl style={{ flex: "1" }}>
                            <InputLabel>Profissional</InputLabel>
                            <Select
                                defaultValue={resource.Id}
                                label="Profissional"
                                disabled={true}
                                onChange={handleResourceChange}>
                                {CompanyConfig.Resources.map((option) => (
                                    <MenuItem value={option.Id}>{option.Label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl style={{ flex: "1" }}>
                            <InputLabel>Procedimento</InputLabel>
                            <Select
                                disabled={true}
                                defaultValue={procedure.Id}
                                label="Procedimento"
                                onChange={handleProcedureChange}>
                                {CompanyConfig.Procedures.map((option) => (
                                    <MenuItem value={option.Id}>{option.Label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {/* <InputField Width={"100%"} Mandatory={false} Label={"Profissional"} Value={resource.Label} Disabled={true} />
                        <InputField Width={"100%"} Mandatory={false} Label={"Procedimento"} Value={procedure.Label} Disabled={true} />*/}
                    </div>
                    <div className="form-line">
                        <InputField Width={"100%"} Mandatory={false} Label={"Valor"} Value={procedure.Price} Disabled={true} />
                        <InputField Width={"100%"} Mandatory={false} Label={"Duração"} Value={procedure.Duration} Disabled={true} />
                    </div>
                </div>
            </div>
        );
    }

    function GetOptionsForSelect() {

        const sortedPatients = patientsList.sort((a, b) => a.name.localeCompare(b.name));

        return sortedPatients.map((item) => ({
            id: item.id,
            label: item.name,
            status: item.status,
        }));
    }

    function OnAutocompleteSelectHandler(value) {
        if (value) {
            setPatientData(value);
        }
        else {
            setPatientData(null);
        }

    }

    function handleResourceChange(event) {
        let resource = GetResourceById(event.target.value);
        setResource(resource);
    }

    function handleProcedureChange(event) {
        let procedure = GetProcedureById(event.target.value);
        inputPriceRef.current.SetValue(procedure.Price);
        inputDurationRef.current.SetValue(procedure.Duration);
        setProcedure(procedure);
    }

    const [open, setOpen] = React.useState(false);
    const [loadingAPI, setLoadingAPI] = React.useState(false);


    useEffect(() => {
        setLoadingAPI(true);
        GetPatients(false, (result) => {
            setPatientsList(result);
            setLoadingAPI(true);
        });

    }, [open]);

    useEffect(() => {
        if (patientsList.length > 0) {
            setSortedPatients(GetOptionsForSelect());
        }
        else {
            setSortedPatients([]);
        }
    }, [patientsList]);


    function getPatientsCombobox() {
        return (
            <Autocomplete
                autoComplete
                options={sortedPatients}
                sx={{ flex: "1" }}
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                onChange={(event, newValue) => {
                    OnAutocompleteSelectHandler(newValue);
                }}
                loading={loadingAPI}
                renderOption={(props, item) => (
                    <li {...props} key={item.key}>
                        <ListItemText>{item.label}</ListItemText>
                    </li>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Paciente"
                        helperText={error ? 'Selecione um paciente' : ''}
                        error={error}
                        slotProps={{
                            input: {
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loadingAPI ? <CircularProgress color="inherit" size={10} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            },
                        }}
                    />
                )}
            />
        )
    }

    function GetNewAppointmentForm() {

        return (
            <div className="form" style={{ marginTop: "10px" }}>
                <div className="form-group">
                    <div className="form-line">
                        {getPatientsCombobox()}
                        <CustomButton variant="outlined" style="secondary" label={""} icon={<IconCreatePatient />} onClick={OnCreateNewPatientClickHander} />
                    </div>
                </div>

                <div className="form-group">
                    <div className="form-line">
                        <FormControl style={{ flex: "1" }}>
                            <InputLabel>Profissional</InputLabel>
                            <Select
                                defaultValue={resource.Id}
                                label="Profissional"
                                onChange={handleResourceChange}>
                                {CompanyConfig.Resources.map((option) => (
                                    <MenuItem value={option.Id}>{option.Label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl style={{ flex: "1" }}>
                            <InputLabel>Procedimento</InputLabel>
                            <Select
                                defaultValue={procedure.Id}
                                label="Procedimento"
                                onChange={handleProcedureChange}>
                                {CompanyConfig.Procedures.map((option) => (
                                    <MenuItem value={option.Id}>{option.Label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="form-line">
                        <InputField ref={inputPriceRef} Width={"100%"} Mandatory={false} Label={"Valor"} Value={procedure.Price} Disabled={true} />
                        <InputField ref={inputDurationRef} Width={"100%"} Mandatory={false} Label={"Duração"} Value={procedure.Duration} Disabled={true} />
                    </div>
                    <div>
                        <p>Enviar lembrete por WhatsApp?</p>
                        <Switch
                            defaultChecked={sendWhatssappReminder}
                            checked={sendWhatssappReminder}
                            onChange={(event) => setSendWhatssappReminder(event.target.checked)}
                        />
                    </div>
                </div>
            </div>
        );
    }

    function OnCreateNewPatientClickHander() {
        let patientData =
        {
            name: inputValue,
        }
        OnOpenCreatePatientPopupHandler(patientData);
    }

    function GetTitle() {
        let title;

        if (Data.status == AppointmentsState.Available) {
            title = "Novo compromisso";
        }
        else {
            title = "Compromisso (#" + Data.id + ")";
        }

        return title;
    }

    function GetContent() {
        let content;

        if (resource == null || procedure == null) {
            return <></>;
        }
        if (Data.status == AppointmentsState.Available) {
            content = GetNewAppointmentForm();
        }
        else {
            content = GetEditAppointmentForm();
        }

        return (
            <>
                <div className="create-appointment-popup-content">
                    <Header Date={Data.date} />
                    {content}
                </div>
                <ConfirmPopup Visibility={deleteAppointmentPopupVisibility} Title={"Excluir compromisso"} Description={"Deseja realmente excluir este compromisso?"} OnConfirm={OnDeleteAppointmentConfirmClickHandler} OnHide={OnDeleteAppointmentCancelClickHandler} />
                <CreatePatientPopup Visibility={createPatientPopupVisibility} OnHide={OnCreatePatientPopupHideHandler} />
            </>
        )
    }

    function GetFooter() {

        return (
            <div style={{ display: "flex", flex: '1', flexDirection: "row", justifyContent: "space-between" }}>
                <div>
                    {Data && Data.status === AppointmentsState.Available && (
                        <CustomButton label={"Cancelar"} onClick={OnCloseClickHandler} />
                    )}
                    {Data && Data.status !== AppointmentsState.Done && Data.invoiceId === "0" && (
                        <CustomButton variant="contained" style="error" label={"Excluir"} onClick={OnDeleteClickHandler} />
                    )}
                </div>
                <div>
                    {Data && Data.id && (Data.status === AppointmentsState.Scheduled ||
                        Data.status === AppointmentsState.Confirmed ||
                        Data.status === AppointmentsState.ConfirmedByPatient ||
                        Data.status === AppointmentsState.Waiting) && (
                            <CustomButton variant="contained" style="primary" label={"Atender"} onClick={OnStartServiceHandler} />
                        )}
                    {
                        Data.status === AppointmentsState.Doing &&
                        <CustomButton variant="contained" style="primary" label={"Ir para atendimento"} onClick={OnStartServiceHandler} />
                    }
                    {Data && Data.status === AppointmentsState.Done && (
                        <CustomButton variant="contained" style="primary" label={"Ver atendimento"} onClick={OnStartServiceHandler} />
                    )}
                    {!Data || !Data.id && (
                        <CustomButton style="primary" variant="contained" label={"Adicionar"} onClick={OnConfirmClickHandler} enabled={patientData != null} />
                    )}
                </div>
            </div>
        );
    }

    function GetRender() {

        return <PopupBase Title={GetTitle()} Content={GetContent()} Footer={GetFooter()} OnClose={OnCloseClickHandler} />
    }

    return (GetRender());
}

export default AppointmentPopupContent;