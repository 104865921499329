import React, { useState, forwardRef, useImperativeHandle } from "react";
import AppointmensState from "../../../consts/AppointmentsState";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const AppointmentStatusSelector = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.Status);
    const statusStyle = AppointmensState.GetDataFromId(value);

    useImperativeHandle(ref, () =>
    ({
        GetValue() {
            return value;
        }
    }));

    const OnItemSelectHandler = (event) => {
        setValue(event.target.value);
    };

    const GetStyleFromState = (id) => {
        return AppointmensState.GetDataFromId(value);
    }

    const GetStatusIcon = () => {
        return statusStyle.icon;
    }
    const GetStyle = () => {
        return {
            backgroundColor: statusStyle.backgroundColor,
            color: statusStyle.textColor,
            width: "100%",
            height: "100%",
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }
    }

    return (
            <FormControl fullWidth>
                <InputLabel>Estado:</InputLabel>
                <Select
                    disabled={props.disabled}
                    value={value}
                    label="Estado"
                    onChange={OnItemSelectHandler}
                    style={{backgroundColor: GetStyle().backgroundColor} }
                >
                    {AppointmensState.Changeable.map((option, index) => {
                        let customStyle = AppointmensState.GetDataFromId(option);
                        let customBgColor = customStyle.backgroundColor;
                        return (
                            <MenuItem key={index} value={option} style={{display:"flex", flexDirection:"row", gap:"20px", backgroundColor: customBgColor, borderRadius:"8px", margin:"10px" }}>{customStyle.icon } {customStyle.label}</MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
    )
});

export default AppointmentStatusSelector;