import React, { useEffect, useState } from 'react';
import { DataProviderInstance } from '../../api/DataProvider';
import Header from './header/Header';
import Loading from '../../components/loading/Loading';
import PatientItem from './list/PatientItem';
import CreatePatientPopup from '../../popup/CreatePatientPopup/CreatePatientPopup';
import Pagination from '@mui/material/Pagination';
import styles from './Patients.module.css'; // Assuming you have a CSS module

function Patients() {
  const { isUpdating, patients } = DataProviderInstance();

  const maxItemsPerPage = 25;
  const [currentPatient, setCurrentPatient] = useState(null);
  const [createPatientPopupVisibility, setCreatePatientPopupVisibility] = useState(false);
  const [filter, setFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [patientsList, setPatientsList] = useState([]);

  useEffect(() => {
    resetCurrentPage();
    updatePatientsList();
  }, [patients, filter]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value - 1);
  };

  const resetCurrentPage = () => {
    setCurrentPage(0);
  };

  const onCreatePatientPopupHideHandler = () => {
    setCurrentPatient(null);
    setCreatePatientPopupVisibility(false);
  };

  const onOpenCreatePatientPopupHandler = () => {
    setCreatePatientPopupVisibility(true);
  };

  const onPatientItemSelectHandler = (id) => {
    const patient = getPatientById(id);
    setCurrentPatient(patient);
    setCreatePatientPopupVisibility(true);
  };

  const getPatientById = (id) => {
    return patients.find((item) => item.id === id);
  };

  const updatePatientsList = () => {
    let filteredList = [...patients];

    if (filter.trim() !== "") {
      filteredList = filteredList.filter((patient) => 
        patient.name.toLowerCase().startsWith(filter.toLowerCase())
      );
    }

    filteredList.sort((a, b) => a.name.localeCompare(b.name));

    setPatientsList(filteredList);
    setTotalPages(Math.ceil(filteredList.length / maxItemsPerPage));
  };

  const getList = () => {
    const list = [];
    const start = currentPage * maxItemsPerPage;
    const end = Math.min(start + maxItemsPerPage, patientsList.length);

    for (let i = start; i < end; i++) {
      const { id, name, cpf, status, phone, email, origin } = patientsList[i];
      list.push(
        <PatientItem
          key={id}
          Id={id}
          Status={status}
          CPF={cpf}
          Name={name}
          Phone={phone}
          Email={email}
          Origin={origin}
          OnSelect={onPatientItemSelectHandler}
        />
      );
    }

    return list;
  };

  const onSearchHandler = (value) => {
    setFilter(value);
  };

  const getPagination = () => (
    <div style={{ display: "flex", flex: '1', flexDirection: "row", paddingBlock: "10px", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", gap: "20px" }}>
      <h5>Página {currentPage + 1} de {totalPages} ({patientsList.length} pacientes)</h5>
      <Pagination count={totalPages} page={currentPage + 1} onChange={handlePageChange} color="primary" shape='rounded' size='small' />
    </div>
  );

  const getContent = () => {
    if (isUpdating) {
      return <Loading />;
    } else {
      return (
        <div>
          <CreatePatientPopup Data={currentPatient} Visibility={createPatientPopupVisibility} OnHide={onCreatePatientPopupHideHandler} />
          <Header OnCreatePatient={onOpenCreatePatientPopupHandler} OnSearch={onSearchHandler} />
          {getPagination()}
          <div className='patients-list'>
            <div className={styles.containerTable}>
              <table className={styles.table}>
                <thead>
                  <tr>
                  <th>Ativo</th>
                    <th>Nome</th>
                    <th>CPF</th>
                    <th>Contato</th>
                    <th>Ficha</th>
                  </tr>
                </thead>
                <tbody>
                  {getList()}
                </tbody>
              </table>
            </div>
          </div>
          {getPagination()}
        </div>
      );
    }
  };

  return (
    <div className='page-content'>
      {getContent()}
    </div>
  );
}

export default Patients;
