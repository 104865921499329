const BASE_URL = "https://www.maisqueagenda.app.br/api/";

const LOGIN = BASE_URL + "login.php";

const COMPANY_CONFIG_GET = BASE_URL + "get_company.php";
const COMPANY_UPDATE = BASE_URL + "update_company.php";
const COMPANY_CONFIG_UPDATE = BASE_URL + "update_company_config.php";

const DASHBOARD_GET = BASE_URL + "get_dashboard.php";

const STATISTICS_GET = BASE_URL + "get_statistics.php";

const REPORTS_APPOINTMENTS_GET = BASE_URL + "get_reports_appointments.php";
const REPORTS_FINANCIAL_GET = BASE_URL + "get_reports_financial.php";

const APPOINTMENTS_TO_CONFIRM_GET = BASE_URL + "get_appointments_to_confirm.php"
const APPOINTMENTS_GET = BASE_URL + "get_appointments.php";
const APPOINTMENTS_SET = BASE_URL + "set_appointment.php";
const APPOINTMENTS_UPDATE = BASE_URL + "update_appointment.php";
const APPOINTMENTS_UPDATE_STATUS = BASE_URL + "update_appointment_status.php";
const APPOINTMENTS_DELETE = BASE_URL + "delete_appointment.php";

const APPOINTMENT_GET_FULLINFO = BASE_URL + "get_appointment_info.php";
const APPOINTMENT_CREATE_INVOICE = BASE_URL + "set_appointment_invoice.php";
const APPOINTMENT_GET_RECEIPT = BASE_URL + "get_receipt_from_appointment.php";
const APPOINTMENT_GET_INVOICE = BASE_URL + "get_invoice.php";
const INVOICE_UPDATE = BASE_URL + "update_invoice.php";

const PATIENTS_GET = BASE_URL + "get_patients.php";
const PATIENT_GET = BASE_URL + "get_patient.php"
const PATIENT_SET = BASE_URL + "set_patient.php";
const PATIENT_UPDATE = BASE_URL + "update_patient.php";

const PATIENT_SET_HISTORY = BASE_URL + "set_patient_history.php";
const PATIENT_SET_COMPLAINTS = BASE_URL + "set_patient_complaint.php";
const PATIENT_SET_ANAMNESE = BASE_URL + "set_patient_anamnese.php";
const PATIENT_SET_CRANIAL_ASYMMETRY = BASE_URL + "set_patient_cranial_asymmetry.php";

const PATIENT_SET_DOCUMENT = BASE_URL + "set_patient_document.php";
const PATIENT_GET_DOCUMENTS = BASE_URL + "get_patient_documents.php";

const WHATSAPP_GET_MESSAGES = BASE_URL + "messages/get_messages.php";
const WHATSAPP_SEND_MESSAGE = BASE_URL + "messages/wa_send_message.php";

const GetWhatsappMessages = (callback) => {
    CallGETAPI(WHATSAPP_GET_MESSAGES, callback);
}

const SendWhatsappMessage = (to, text, callback) => {
    let json = "phone=" + to + "&message=" + text;
    console.log('SendWhatsappMessage:', json);
    CallPOSTAPI(WHATSAPP_SEND_MESSAGE, json, callback);
}

const GetInvoiceAPI = (id, callback) => {
    let json = "id=" + id;
    CallPOSTAPI(APPOINTMENT_GET_INVOICE, json, callback);
}

const UpdateInvoiceAPI = (invoiceId, price, paymentMethod, issueDocument, updatedBy, callback) => {
    let json = "id=" + invoiceId + "&price=" + price + "&paymentMethod=" + paymentMethod + "&issueDocument=" + issueDocument + "&updatedBy=" + updatedBy;
    CallPOSTAPI(INVOICE_UPDATE, json, callback);
}

const GetAppointmentFullInfo = (id, callback) => {
    let json = "id=" + id;
    CallPOSTAPI(APPOINTMENT_GET_FULLINFO, json, callback);
}

const SetPatientAnamneseAPI = (date, createdBy, patientId, appointmentId, anamnese, callback) => {
    let json = "appointmentId=" + appointmentId +
        "&createdAt=" + date +
        "&patientId=" + patientId +
        "&createdBy=" + createdBy +
        "&anamnese=" + JSON.stringify(anamnese);

    CallPOSTAPI(PATIENT_SET_ANAMNESE, json, callback);
}

const SetPatientComplaints = (date, patientId, appointmentId, complaints, callback) => {
    let json = "appointmentId=" + appointmentId +
        "&createdAt=" + date +
        "&patientId=" + patientId +
        "&complaints=" + JSON.stringify(complaints);

    CallPOSTAPI(PATIENT_SET_COMPLAINTS, json, callback);
}

const SetPatientDocumentAPI = (patientId, date, appointmentId, title, description, createdBy, callback) => {

    let json = "appointmentId=" + appointmentId +
        "&date=" + date +
        "&patientId=" + patientId +
        "&title=" + title +
        "&description=" + description +
        "&createdBy=" + createdBy;

    CallPOSTAPI(PATIENT_SET_DOCUMENT, json, callback);
}

const GetPatientDocumentsAPI = (patientId, callback) => {
    let json = "patientId=" + patientId;
    CallPOSTAPI(PATIENT_GET_DOCUMENTS, json, callback);
}

const SetPatientHistoryAPI = (date, createdBy, patientId, appointmentId, assessment, evolution, callback) => {

    let json = "appointmentId=" + appointmentId +
        "&createdAt=" + date +
        "&patientId=" + patientId +
        "&assessment=" + assessment +
        "&evolution=" + evolution +
        "&createdBy=" + createdBy;

    CallPOSTAPI(PATIENT_SET_HISTORY, json, callback);
}

const SetPatientCranialAsymmetryAPI = (date, createdBy, patientId, appointmentId, ap, bp, dd, de, callback) => {
    let json = "appointmentId=" + appointmentId +
        "&createdAt=" + date +
        "&patientId=" + patientId +
        "&createdBy=" + createdBy +
        "&ap=" + ap +
        "&bp=" + bp +
        "&dd=" + dd +
        "&de=" + de;

    CallPOSTAPI(PATIENT_SET_CRANIAL_ASYMMETRY, json, callback);
}

const SetAppointmentInvoiceAPI = (appointmentId, companyId, createdBy, patient, price, paymentMethod, items, issueDocument, callback) => {
    
    let json = "appointmentId=" + appointmentId +
        "&companyId=" + companyId +
        "&patient=" + patient +
        "&price=" + price +
        "&paymentMethod=" + paymentMethod +
        "&issueDocument=" + issueDocument +
        "&items=" + JSON.stringify(items) +
        "&createdBy=" + createdBy;

        console.log('SetAppointmentInvoiceAPI:', issueDocument);

   CallPOSTAPI(APPOINTMENT_CREATE_INVOICE, json, callback);
}

const UpdateCompanyAPI = (companyId, data, callback) => {
    const formData = new FormData();
    formData.append("id", companyId);
    formData.append("name", data.name);
    formData.append("phone", data.phone);
    formData.append("email", data.email);
    formData.append("cnpj", data.cnpj);
    formData.append("cep", data.cep);
    formData.append("address", data.address);
    formData.append("number", data.number);
    formData.append("complement", data.complement);
    formData.append("district", data.district);
    formData.append("city", data.city);
    formData.append("state", data.state);
    formData.append("country", data.country);
    formData.append("logo", data.logo); // Assuming data.logo is a File object

    CallPOSTAPIWithFormData(COMPANY_UPDATE, formData, callback);
}

const UpdateCompanyConfigAPI = (companyId, data, callback) => {

    const formData = new FormData();
    formData.append("id", companyId);
    formData.append("availableDaysOfWeek", JSON.stringify(data.availableDaysOfWeek));
    formData.append("availableTime", JSON.stringify(data.availableTime));
    formData.append("blockedTimes", JSON.stringify(data.blockedTimes));
    formData.append("targetOccupation", data.targetOccupation);
    formData.append("timeInterval", data.timeInterval);
    formData.append("goalAppointments", data.goalAppointments);
    formData.append("goalBilling", data.goalBilling);
    formData.append("allowAutomaticWhatsappMessages", data.allowAutomaticWhatsappMessages);

    console.log('UpdateCompanyConfigAPI:', formData);

   CallPOSTAPIWithFormData(COMPANY_CONFIG_UPDATE, formData, callback);
}

const CallPOSTAPIWithFormData = (path, formData, callback) => {
    const token = localStorage.getItem('token');

    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: formData
    };

    fetch(path, requestOptions)
        .then(response => {
            if (response.status === 401) {
                console.warn('Token expirado, redirecionando para login.');
                Disconnect();
                return;
            }
            return response.text();
        })
        .then(data => {
            try {
                if (data) {
                    const jsonData = JSON.parse(data);
                    callback(jsonData);
                } else {
                    console.error('Dados vazios recebidos da API');
                }
            } catch (e) {
                console.error('Erro ao analisar o JSON:', path, e);
            }
        })
        .catch(error => {
            console.error('Erro na requisição:', error);
        });
}

const GetCompanyConfigAPI = (companyId, callback) => {
    let json = "id=" + companyId;
    CallPOSTAPI(COMPANY_CONFIG_GET, json, callback);
}

const LoginAPI = (email, password, callback) => {
    let json = "email=" + email + "&password=" + password;
    CallPOSTAPI(LOGIN, json, callback);
}

const GetDashboardAPI = (companyId, callback) => {
    let json = "companyId=" + companyId;
    CallPOSTAPI(DASHBOARD_GET, json, callback);
}

const GetStatisticsAPI = (companyId,  startDate, endDate,callback) => {

    let json = "companyId=" + companyId + "&startDate=" + startDate + "&endDate=" + endDate;

    CallPOSTAPI(STATISTICS_GET, json, (response) => {
         //console.log('GetStatisticsAPI:', response);
       callback(response);
    });
}

const GetReportsAppointmentsAPI = (companyId, startDate, endDate, callback) => {
    let json = "companyId=" + companyId + "&startDate=" + startDate + "&endDate=" + endDate;
    CallPOSTAPI(REPORTS_APPOINTMENTS_GET, json, callback);
}

const GetReportsFinancialAPI = (companyId, startDate, endDate, callback) => {
    let json = "companyId=" + companyId + "&startDate=" + startDate + "&endDate=" + endDate;
    CallPOSTAPI(REPORTS_FINANCIAL_GET, json, callback);
}

const GetAppointmentsAPI = (companyId, callback) => {
    let json = "companyId=" + companyId;
    CallPOSTAPI(APPOINTMENTS_GET, json, callback);
}

const SetAppointmentAPI = (companyId, createdBy, data, callback) => {
    let json = "status=" + data.status + "&date=" + data.date + "&patient=" + data.patient + "&resource=" + data.resource + "&procedure=" + data.procedure + "&duration=" + data.duration + "&price=" + data.price + "&companyId=" + companyId + "&createdBy=" + createdBy;
    CallPOSTAPI(APPOINTMENTS_SET, json, callback);
}

const UpdateAppointmentStatusAPI = (id, status, callback) => {
    let json = "id=" + id + "&status=" + status;
    CallPOSTAPI(APPOINTMENTS_UPDATE_STATUS, json, callback);
}
const UpdateAppointmentAPI = (data, callback) => {
    let json = "id=" + data.id + "&status=" + data.status + "&date=" + data.date + "&patient=" + data.patient + "&resource=" + data.resource + "&procedure=" + data.procedure + "&duration=" + data.duration + "&price=" + data.price;
    CallPOSTAPI(APPOINTMENTS_UPDATE, json, callback);
}

const DeleteAppointmentAPI = (id, callback) => {
    let json = "id=" + id;
    CallPOSTAPI(APPOINTMENTS_DELETE, json, callback);
}

const GetAppointmentsToConfirmAPI = (companyId, callback) => {
    let json = "companyId=" + companyId;
    CallPOSTAPI(APPOINTMENTS_TO_CONFIRM_GET, json, callback);
}

const GetPatientAPI = (id, callback) => {
    let json = "id=" + id;
    CallPOSTAPI(PATIENT_GET, json, callback);
}

const GetPatientsAPI = (companyId, callback) => {
    let json = "companyId=" + companyId;
    CallPOSTAPI(PATIENTS_GET, json, callback);
}

const SetPatientAPI = (companyId, createdBy, data, callback) => {
    let json = "name=" + data.name +
        "&companyId=" + companyId +
        "&phone=" + data.phone +
        "&cpf=" + data.cpf +
        "&email=" + data.email +
        "&birthday=" + data.birthday +
        "&gender=" + data.gender +
        "&cep=" + data.cep +
        "&address=" + data.address +
        "&number=" + data.number +
        "&complement=" + data.complement +
        "&district=" + data.district +
        "&city=" + data.city +
        "&state=" + data.state +
        "&origin=" + data.origin +
        "&createdBy=" + createdBy;

    CallPOSTAPI(PATIENT_SET, json, callback);
}

const UpdatePatientAPI = (data, callback) => {
    let json = "id=" + data.id +
        "&status=" + data.status +
        "&name=" + data.name +
        "&phone=" + data.phone +
        "&cpf=" + data.cpf +
        "&email=" + data.email +
        "&birthday=" + data.birthday +
        "&gender=" + data.gender +
        "&cep=" + data.cep +
        "&address=" + data.address +
        "&number=" + data.number +
        "&complement=" + data.complement +
        "&district=" + data.district +
        "&city=" + data.city +
        "&state=" + data.state +
        "&origin=" + data.origin;

    CallPOSTAPI(PATIENT_UPDATE, json, callback);
}

const GetCEPInfoAPI = (value, callback) => {
    CallGETAPI("https://viacep.com.br/ws/" + value + "/json/", callback);
}

const CallGETAPI = (path, callback) => {
    fetch(path)
        .then(response => response.json())
        .then(data => callback(data));
}

const Disconnect = () => {

    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('password');

    window.location.href = '/';
}

const CallPOSTAPI = (path, body, callback) => {

   // console.trace('CallPOSTAPI:', path, body);

    const token = localStorage.getItem('token');

    const requestOptions =
    {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: body
    };

    fetch(path, requestOptions)
        .then(response => {
            // Verifica se o token expirou (status 401)
            if (response.status === 401) {
                // Token expirado, deslogar o usuário
                console.warn('Token expirado, redirecionando para login.');

                Disconnect();

                return; // Para a execução aqui
            }

            // Se não for 401, continua normalmente
            return response.text();
        })
        .then(data => {
            try {
                // Verifica se 'data' não está vazio antes de tentar o parse
                if (data) {
                    const jsonData = JSON.parse(data);
                    callback(jsonData);
                } else {
                    console.error('Dados vazios recebidos da API');
                }
            } catch (e) {
                console.error('Erro ao analisar o JSON:', path, e);
            }
        })
        .catch(error => {
            console.error('Erro na requisição:', error);
        });
}

export {
    APPOINTMENT_GET_RECEIPT,
    LoginAPI,
    GetCompanyConfigAPI,UpdateCompanyAPI,UpdateCompanyConfigAPI,
    GetCEPInfoAPI,
    GetDashboardAPI,
    GetStatisticsAPI ,
    GetReportsAppointmentsAPI,GetReportsFinancialAPI,
    GetAppointmentsToConfirmAPI,
    UpdateAppointmentStatusAPI,
    GetPatientsAPI, GetPatientAPI, SetPatientAPI, UpdatePatientAPI,
    SetPatientHistoryAPI, SetPatientComplaints, SetPatientAnamneseAPI, SetPatientCranialAsymmetryAPI , SetPatientDocumentAPI, GetPatientDocumentsAPI,
    GetAppointmentsAPI, SetAppointmentAPI, UpdateAppointmentAPI, DeleteAppointmentAPI,
    SetAppointmentInvoiceAPI,
    GetAppointmentFullInfo,
    GetInvoiceAPI,UpdateInvoiceAPI,
    GetWhatsappMessages, SendWhatsappMessage
};