import React, {useRef, useState, useEffect} from 'react';
import AreaTitle from '../../../../components/areaTitle/AreaTitle';
import DateRangeHeader from '../../../../components/dateRangeHeader/DateRangeHeader';
import { DataProviderInstance } from '../../../../api/DataProvider';
import FilterList from '../../filters/FilterList';
import ListItem from './list/ListItem';
import styles from './Appointments.module.css';
import Loading from '../../../../components/loading/Loading';
import AppointmentsState from '../../../../consts/AppointmentsState';

const Appointments = () => {

    const { GetResourceById, GetProcedureById, GetReportsAppointments, GetPatientById } = DataProviderInstance();

    const usingStatesSource = AppointmentsState.Filters;
    const [filtersByState, setFiltersByState] = useState(usingStatesSource.map((item, index) => index));


    const [list, setList] = React.useState([]);
    const [filtered, setFiltered] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);	
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const filterRef = useRef(null);
    
    function formatDate(date) {
        if (!date) return '';
        const [year, month, day] = date.split('-');
        return `${day}/${month}/${year}`;
    }

    useEffect(() => {
        
        const today = new Date();
        const initialDate = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split('T')[0];
        const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString().split('T')[0];

        handleDateChange(initialDate, endDate);
    }, []);

    
    useEffect(() => {
        GetFilteredList();
    }, [filtersByState]);

    useEffect(() => {
        GetFilteredList();
    }, [list]);


    const handleDateChange = (initialDate, endDate) => {
        setStartDate(initialDate);
        setEndDate(endDate);
        loadData(initialDate, endDate);
    }

    const loadData = (initialDate, endDate) => {
        setIsLoading(true);
        GetReportsAppointments(initialDate, endDate, (response) => {
            setList(response.data);
            setIsLoading(false);

        } );
    }

    function onFilterByAppointmentsStatusChangeHandler(filters) {
        setFiltersByState(filters);
      }

    const GetFilteredList = () => {
        let filteredList = [...list];

         // Filter By Status
         filteredList = filteredList.filter((item) => filtersByState.includes(usingStatesSource.indexOf(item.status)));

        setFiltered(filteredList);
    }

    function renderItem(item, index) {
        const patientData = GetPatientById(item.patient);
        const resource = GetResourceById(item.resource).Label;
        const procedure = GetProcedureById(item.procedure).Label;
        const status = AppointmentsState.GetLabel(item.status);

        return (
            <ListItem key={index} id={item.id} date={item.date} name={patientData.name} resource={resource} procedure={procedure} status={status}/>
        );
    }

    const filtersStates = usingStatesSource.map((item) => AppointmentsState.GetLabel(item));

    return (
        <div className={styles.mainContainer}>
            <AreaTitle Title="Atendimentos" />
            <DateRangeHeader onChange={handleDateChange} />
            <FilterList ref={filterRef} id="reportsAppointments" label="Filtros" filters={filtersStates} onChange={onFilterByAppointmentsStatusChangeHandler} initOpened={true}/>
            {isLoading && <Loading />}
            <div className={styles.containerResume}>
            <AreaTitle Title="Resultado" Description={`<b>${filtered.length} consultas</b> encontradas no período de <b>${formatDate(startDate)} à ${formatDate(endDate)}</b>.`}/>
            </div>
            <div className={styles.containerTable}>
             <table className={styles.table}>
                <thead>
                    <tr className={styles.tr}>
                        <th>Data</th>
                        <th>Hora</th>
                        <th>Situação</th>
                        <th>Paciente</th>
                        <th>Profissional</th>
                        <th>Procedimento</th>
                    </tr>
                </thead>
                <tbody>
                    {filtered && filtered.map((item, index) => (
                       renderItem(item, index)
                    ))}
                </tbody>
            </table>
            </div>
        </div>
    );
};

export default Appointments;